<style scoped>
  .nav {
    position: relative;
    z-index: 10;
    padding: 18px;
    background-color: #e8e8e8;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 5px 10px 18px #494949;
  }
  .name {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .links {

  }
  a {
    transition: 0.4s all;
    padding: 8px;
    margin: 8px;
  }
  a:hover {
    background-color: rgb(0, 72, 212);
  }
</style>

<template>
  <!--div id="nav">
    <router-link to="/">Home</router-link>
    |
    <router-link to="/about">About</router-link>
    |
    <router-link to="/about">Skills</router-link>
    |
    <router-link to="/about">Projects</router-link>
    |
    <router-link to="/about">Contact</router-link>
  </div-->
  <div class="nav">
    <div class="name">
        <img class="w-12 h-12 rounded-full mr-4" :src="portrait">
        Keith McAvoy
    </div>
    <div class="links">
      <a href="#home">Home</a>
      |
      <a href="#about">About</a>
      |
      <a href="#skills">Skills</a>
      |
      <a href="#projects">Projects</a>
      |
      <a href="#experience">Experience</a>
      <!--|-->
      <!--a href="#references">References</a-->
      |
      <a href="#contact">Contact</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppHeader',
    data(){
        return {
            portrait: '/portraits/1571854668715.jpg',
        };
    },
  }
</script>
