<style>
  @import 'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css';
  body {
    background-color: #ffffff;
    color: black;
    text-align: center;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<template>
    <div>
        <app-header />
        <router-view />
        <app-footer />
    </div>
</template>

<script>
  import AppHeader from '@/components/App/Header'
  import AppFooter from '@/components/App/Footer'
  import 'vueperslides/dist/vueperslides.css'

  export default {
    name: 'AppLayout',
    components: {
      AppHeader,
      AppFooter,
    },
  }
</script>
