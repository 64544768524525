<style scoped>
  .project-title {
    font-weight: bolder;
    font-size: 24px;
  }
  .project-detail {
      font-size: 16px;
      padding: 24px 0px;
  }
  .project-link {
      color: blue;
  }
</style>

<template>
  <segment id="projects" :color="color">
    <div class="title">Projects</div>
    <div class="detail">These are projects I made have created</div>
    <experience title="Clifford Power Portal" class="mt-10">
        <template #detail>
            <a href="https://portal.cliffordpower.com/" target="_blank" class="text-blue-600">https://portal.cliffordpower.com/</a>
        </template>
        <template #left>
            Created this with laravel inertia.
        </template>
        <template #right>
            <open :image="cliffordLogo" :video="cliffordVideo" />
        </template>
    </experience>
    <experience title="Staff Support Discord Bot">
        <template #detail>
            <a href="http://51.222.41.186:8000/" target="_blank" class="text-blue-600">http://51.222.41.186:8000/</a>
        </template>
        <template #left>
            Manages staff duty and player tickets and reports. Also, serves web pages to display server rules, staff roster and member profile information. Developed using node.js, laravel, bootstrap and backpack.
        </template>
        <template #right>
            <open :image="lfawBanner" :video="lfawVideo" />
        </template>
    </experience>
    <experience title="Wolf Simulator Website">
        <template #detail>
            <a href="https://northerncall.net/" target="_blank" class="text-blue-600">https://northerncall.net/</a>
        </template>
        <template #left>
            Members collect wolves and items through raffles, exploration, hunting, trading and in game purchases with pay-pal. Developed user laravel, vue.js and bootstrap.
        </template>
        <template #right>
            <open :image="ncSplashscreen" :video="northerncallVideo" />
        </template>
    </experience>
    <experience title="Educational Apps for kids">
        <template #detail>
            <a href="https://www.eduguru.co.uk/" target="_blank" class="text-blue-600">https://www.eduguru.co.uk/</a>
        </template>
        <template #left>
            Created phone/tablet Math and English apps for kids. Developed using C++ and AGK.
        </template>
        <template #right>
            <img :src="guruLogo" />
        </template>
    </experience>
  </segment>
</template>

<script>
  import Segment from '@/components/Segment'
  import Experience from '@/components/Experience'
  import Open from '@/components/Open'

  export default {
    name: 'Projects',
    components: {
        Segment,
        Experience,
        Open,
    },
    props: {
        color: String,
    },
    data() {
      return {
        cliffordLogo: '/tiles/clifford-power-logo.svg',
        cliffordVideo: '/videos/clifford-power.mp4',

        lfawBanner: '/tiles/lfaw-Header_Banner_v1.png',
        lfawVideo: '/videos/lfaw support.mp4',

        ncSplashscreen: '/tiles/nc-splashscreen.png',
        northerncallVideo: '/videos/northerncall.mp4',

        guruLogo: '/tiles/guru-logo.svg',
      };
    },
  }
</script>
