<style scoped>
  .segment {
    padding: 0px 16px 64px 16px;
  }
</style>

<template>
  <div :style="'background-color:'+color">
      <img v-if="image" :src="image">
      <div class="container mx-auto lg:w-9/12">
        <div v-if="id" :id="id" class="h-24"></div>
        <div class="segment relative h-full">
          <slot></slot>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Segment',
    props: {
      id: String,
      color: String,
      image: String,
    },
  }
</script>
