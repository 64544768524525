<template>
    <segment id="home" :image="image" :color="color">
      <div class="relative flex justify-center items-center h-96">
        <vueper-slides class="w-full h-full no-shadow" fixedHeight="100%">
            <vueper-slide v-for="(slide, i) in slides" :key="i">
                <template #content v-if="i % 2 === 0">
                    <div class="flex justify-around items-center h-full p-5">
                        <div class="flex-1 p-3">
                            <div class="font-bold text-lg">{{ slide.title }}</div>
                            <div class="mt-3">{{ slide.content }}</div>
                        </div>
                        <div class="flex-1 p-3">
                            <img class="w-full h-full" :src="slide.image">
                        </div>
                    </div>
                </template>
                <template #content v-else>
                    <div class="flex justify-around items-center h-full p-5">
                        <div class="flex-1">
                            <img class="w-full h-full" :src="slide.image">
                        </div>
                        <div class="flex-1">
                            <div class="font-bold text-lg">{{ slide.title }}</div>
                            <div class="mt-3">{{ slide.content }}</div>
                        </div>
                    </div>
                </template>
            </vueper-slide>
        </vueper-slides>
      </div>
    </segment>
</template>

<script>
  import Segment from '@/components/Segment'
  import { VueperSlides, VueperSlide } from 'vueperslides'

  export default {
    name: 'Home',
    components: {
        Segment,
        VueperSlides,
        VueperSlide,
    },
    props: {
        color: String,
        image: String,
    },
    data() {
      return {
        slides: [
            {
                title: 'Full Stack Software Developer',
                content: 'Proficient with both front-end and back-end website development.',
                image: '/portraits/IMG_0078.jpg',
            },
            {
                title: 'Bot Application Engineer',
                content: 'Created various discord bot applications.',
                image: '/portraits/IMG_0237.jpg',
            },
        ],
      };
    },
  }
</script>
