<style scoped>
  .description {
      list-style-type: disc;
      padding: 24px 0px;
  }
  .description li {
      padding: 6px 0px;
  }
  .software {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      color: darkblue;
  }
  .software li {
      padding: 4px 8px;
  }
  .scale {
      transition: all 0.4s;
  }
  .scale:hover {
      transform: scale(1.01);
      filter: brightness(110%);
  }
</style>

<template>
  <segment id="experience" :color="color">
    <div class="title">Experience</div>
    <experience title="Clifford Power" detail="Tulsa, OK" >
        <ul class="description">
            <li>Created a portal site which supports user login and email transactions.</li>
            <li>Synchronized data between software and displayed this data to clients.</li>
        </ul>
        <ul class="software">
            <li>PHP</li>
            <li>Laravel</li>
            <li>Backpack</li>
            <li>MySQL</li>
            <li>VueJs</li>
            <li>InertiaJs</li>
            <li>Tailwind CSS</li>
            <li>PHP Unit</li>
            <li>Dusk</li>
            <li>Telescope</li>
            <li>IIS</li>
        </ul>
    </experience>
    <experience title="Clevyr" detail="Oklahoma City, OK" >
        <ul class="description">
            <li>Developed roles and permissions to perform security breakdowns site wide for site admins to customize.</li>
            <li>Strove for an unprecedented test coverage of nearly one hundred percent.</li>
        </ul>
        <ul class="software">
            <li>Ruby on Rails</li>
            <li>Angular</li>
            <li>Typescript</li>
            <li>PostgreSQL</li>
            <li>SCSS</li>
            <li>CircleCI</li>
        </ul>
    </experience>
    <experience title="ScaleFactor, Inc" detail="Austin, TX" >
        <ul class="description">
            <li>Created a central access point for multiple API to communicate using Pusher.</li>
            <li>Made an employee facing graph builder application for constructing custom built workflows which handled back-end processes.</li>
        </ul>
        <ul class="software">
            <li>Ruby on Rails</li>
            <li>EmberJs</li>
            <li>Typescript</li>
            <li>PostgreSQL</li>
            <li>Pusher</li>
            <li>AWS</li>
            <li>Jenkins</li>
        </ul>
    </experience>
    <experience title="Suncor Stainless" detail="Plymouth, MA" >
        <ul class="description">
            <li>Develop applications and system management tools reducing manual efforts and increasing overall efficiency</li>
            <li>Oversee all web and e-marketing development to optimize sales</li>
            <li>Provide application support for end-users</li>
            <li>Developed and launched complete intranet revamp allowing personnel to quickly retrieve</li>
            <li>key product and customer information</li>
        </ul>
        <ul class="software">
            <li>PHP</li>
            <li>Laravel</li>
            <li>LDAP</li>
            <li>MySQL</li>
            <li>MsSQL</li>
            <li>JavaScript</li>
            <li>jQuery</li>
            <li>IIS</li>
        </ul>
    </experience>
  </segment>
</template>

<script>
  import Segment from '@/components/Segment'
  import Experience from '@/components/Experience'

  export default {
    name: 'Experiences',
    components: {
        Segment,
        Experience,
    },
    props: {
        color: String,
    },
    data() {
        return {
            cliffordLogo: '/tiles/clifford-power-logo.svg',
            cliffordVideo: '/videos/clifford-power.mp4',
            showCliffordVideo: false,
            slides: [
                {
                    title: 'Slide #1',
                    content: 'Slide content.',
                    image: Image,
                },
                {
                    title: 'Slide #2',
                    content: 'Slide content.',
                    image: Image,
                },
            ],
        };
    },
  }
</script>
