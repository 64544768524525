<template>
  <segment id="skills" :color="color">
    <div class="title">Skills</div>
    <div class="flex flex-wrap justify-evenly">
      <list v-for="(list, index) in lists" :key="index" :title="list.title" :items="list.items" />
    </div>
  </segment>
</template>

<script>
  import Segment from '@/components/Segment'
  import List from '@/components/List'

  export default {
    name: 'Skills',
    components: {
        Segment,
        List,
    },
    props: {
        color: String,
    },
    data(){
        return {
            lists: [
                {
                    title: 'Languages',
                    items: [
                        { name: 'PHP', icon: 'lab la-php' },
                        { name: 'C++', icon: 'las la-plus' },
                        { name: 'JavaScript', icon: 'las la-file-code' },
                        { name: 'Ruby', icon: 'las la-gem' },
                    ],
                },
                {
                    title: 'Web Frameworks',
                    items: [
                        { name: 'Laravel', icon: 'lab la-laravel' },
                        { name: 'Ruby on Rails', icon: 'las la-gem' },
                    ],
                },
                {
                    title: 'Front-end',
                    items: [
                        { name: 'EmberJs', icon: 'lab la-ember' },
                        { name: 'VueJs', icon: 'lab la-vuejs' },
                        { name: 'Angular', icon: 'lab la-angular' },
                        { name: 'Bootstrap', icon: 'lab la-bootstrap' },
                        { name: 'Tailwind', icon: 'las la-wind' },
                    ],
                },
                {
                    title: 'Databases',
                    items: [
                        { name: 'MySQL', icon: 'las la-database' },
                        { name: 'PostgreSQL', icon: 'las la-table' },
                    ],
                },
                {
                    title: 'Authentication',
                    items: [
                        { name: 'LDAP', icon: 'las la-lock' },
                        { name: 'Discord API', icon: 'lab la-discord' },
                        { name: 'Steam API', icon: 'lab la-steam-symbol' },
                        { name: 'Patreon API', icon: 'lab la-patreon' },
                        { name: 'Google API', icon: 'lab la-google-drive' },
                    ],
                },
                {
                    title: 'Version Control',
                    items: [
                        { name: 'GIT', icon: 'lab la-github' },
                        { name: 'Bonobo', icon: 'lab la-git' },
                    ],
                },
                {
                    title: 'Game Development',
                    items: [
                        { name: 'AGK', icon: 'las la-chess-knight' },
                        { name: 'DarkGDK', icon: 'las la-dice-five' },
                        { name: 'Unreal', icon: 'las la-dice-d6' },
                        { name: 'Unity', icon: 'las la-gamepad' },
                    ],
                },
            ],
        };
    },
  }
</script>
