<template>
  <div>
    <home :color="light" />
    <about :color="dark" />
    <skills :color="light" />
    <projects :color="dark" />
    <experiences :color="light" />
    <references :color="dark" />
    <contact :color="light" />
  </div>
</template>

<script>
  import Home from '@/components/Landing/Home'
  import About from '@/components/Landing/About'
  import Skills from '@/components/Landing/Skills'
  import Projects from '@/components/Landing/Projects'
  import Experiences from '@/components/Landing/Experiences'
  import References from '@/components/Landing/References'
  import Contact from '@/components/Landing/Contact'

  export default {
    name: 'Landing',
    components: {
      Home,
      About,
      Skills,
      Projects,
      Experiences,
      References,
      Contact,
    },
    computed: {
        light(){
            return '#f1f1f1';
        },
        dark(){
            return '#eceaea';
        },
    },
  }
</script>
