<template>
  <div class="text-right p-5 text-xs">
      Copyright <i class="las la-copyright"></i> 2021. All rights reserved.
  </div>
</template>

<script>
  export default {
    name: 'AppFooter',
  }
</script>
