<template>
  <segment id="references" :color="color">
    <div class="title">Reviews</div>
    <div class="detail">Messages from the people I've worked with</div>
    <experience title="Clifford Power" detail="Project Manager" class="mt-10">
        <div class="italic mt-6">"
            Keith was easy & professional to work with, taking our project from a rough concept to a fully-realized functioning product well under deadline.
             As the concept & scope of the project changed & grew, Keith was able to quickly adapt and provide innovative solutions to meet our needs.
             In the end, we were extremely pleased with the results, as our Customer Portal site both looks & feels even better than they imagined!
        "</div>
    </experience>
  </segment>
</template>

<script>
  import Segment from '@/components/Segment'
  import Experience from '@/components/Experience'

  export default {
    name: 'References',
    components: {
        Segment,
        Experience,
    },
    props: {
        color: String,
    },
  }
</script>
