<template>
  <div class="p-3 m-3 flex flex-col justify-items-center w-64 bg-gray-100 border-2 border-gray-300 rounded-xl">
    <div class="text-xl font-bold underline p-3">{{ title }}</div>
    <div class="flex justify-center">
        <ul class="text-left">
          <li v-for="item in items" :key="'item_'+item" class=""><i class="text-lg pr-4" :class="item.icon"></i> {{ item.name }}</li>
        </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'List',
    props: {
      title: String,
      items: Array,
    },
  }
</script>
