<style scoped>
  .list {
    table-layout: fixed;
    text-align: left;
    margin-top: 24px;
  }
  .list th {
    font-weight: bold;
    padding: 4px 24px;
  }
  .list td {
    color: orange;
    padding: 4px 24px;
  }
</style>

<template>
  <segment id="about" :color="color">
    <div class="title">About Me</div>
    <div class="detail">
        I have a great passion for programming and developing applications.
        I enjoy learning and furthering my knowledge about all aspects of the programming world.
        Being able to create software that people enjoy using gives me great joy.
    </div>
    <div class="flex justify-center">
      <table class="list">
        <tr>
          <th>Name:</th>
          <td>Keith McAvoy</td>
        </tr>
        <tr>
          <th>Address:</th>
          <td>Oklahoma City, OK</td>
        </tr>
        <tr>
          <th>Zip Code:</th>
          <td>73104</td>
        </tr>
        <tr>
          <th>Email:</th>
          <td>keith@mcavoy.dev</td>
        </tr>
        <tr>
          <th>Phone:</th>
          <td>(413) 320-1066</td>
        </tr>
      </table>
    </div>
  </segment>
</template>

<script>
  import Segment from '@/components/Segment'

  export default {
    name: 'About',
    components: {
        Segment,
    },
    props: {
        color: String,
    },
  }
</script>
