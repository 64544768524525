<style scoped>
  .image {
      cursor: pointer;
      transition: all 0.4s;
  }
  .image:hover {
      transform: scale(1.1);
      filter: brightness(110%);
  }
</style>

<template>
  <div>
    <img v-if="!showVideo" :src="image" @click="showVideo = true" class="image" />
    <video v-else controls>
        <source :src="video" type="video/mp4">
    </video>
  </div>
</template>

<script>
  export default {
    name: 'Open',
    props: [
        'image',
        'video',
    ],
    data() {
        return {
            showVideo: false,
        };
    },
  }
</script>
