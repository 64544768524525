<style scoped>
  .description {
      list-style-type: disc;
      padding: 24px 0px;
  }
  .description li {
      padding: 6px 0px;
  }
  .software {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      color: darkblue;
  }
  .software li {
      padding: 4px 8px;
  }
</style>

<template>
    <div class="text-left py-8 m-auto" :class="{ 'md:w-9/12': !hasSlots }">
        <div v-if="title" class="title">{{ title }}</div>
        <div v-if="detail" class="detail">{{ detail }}</div>
        <slot v-if="$slots.detail" name="detail"></slot>
        <div v-if="hasSlots" class="flex flex-wrap flex-evenly justify-center items-start">
            <div class="md:flex-1 pr-3 py-5">
                <slot name="left"></slot>
            </div>
            <div class="md:flex-1 pl-3">
                <slot name="right"></slot>
            </div>
        </div>
        <div v-else class="flex justify-center">
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Experience',
    props: {
        title: String,
        detail: String,
    },
    computed: {
        hasSlots() {
            return this.$slots.left || this.$slots.right;
        },
    },
  }
</script>
