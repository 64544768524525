<style scoped>
    th {
        text-align: right;
        padding-right: 16px;
    }
    td {
        padding: 6px 0px;
        text-align: left;
        color: blue;
    }
</style>

<template>
  <segment id="contact" :color="color">
    <div class="title">Contact</div>
    <div class="flex justify-center">
        <table>
            <tr>
                <th>Linked In:</th>
                <td><a href="https://linkedin.com/in/keith-mcavoy/" target="_blank">https://linkedin.com/in/keith-mcavoy/</a></td>
            </tr>
            <tr>
                <th>Email Me:</th>
                <td><a href="mailto:keith@mcavoy.dev" target="_blank">keith@mcavoy.dev</a></td>
            </tr>
        </table>
    </div>
    <div class="mt-3">
        <a class="border border-black rounded p-2 hover:bg-gray-300" :href="resume" download>Download Resume</a>
    </div>
  </segment>
</template>

<script>
  import Segment from '@/components/Segment'

  export default {
    name: 'Contact',
    components: {
        Segment,
    },
    props: {
        color: String,
    },
    data() {
        return {
            resume: `/files/Keith McAvoy - Resume.pdf`,
        };
    },
  }
</script>
